import classNames from "classnames";
import React, { useMemo } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Card } from "react-onsenui";
import { store } from "react-recollect";
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { IGroupStatistics, IUser } from "../../../api/domain";
import { firestore } from "../../../firebase";
import "./total-costs-statistics.scss";

interface IOwnProps {
    className?: string;
    groupId: string;
}

interface ITotalCostsStatisticsProps extends IOwnProps {}

interface IMemberCosts {
    displayName: string;
    costs: number;
}
const formatChf = (amount: number) => `${amount.toFixed(2)}\nCHF`;
const colors = ["#ee6352", "#57a773", "#08b2e3", "#df6bff", "#efe9f4"];

export const TotalCostsStatistics: React.FC<ITotalCostsStatisticsProps> = ({ className, groupId }) => {
    const [disciplineStatistic] = useDocumentData<IGroupStatistics>(firestore.collection("statistics").doc(groupId));
    const memberCosts: IMemberCosts[] = useMemo(
        () =>
            Object.keys(disciplineStatistic?.totalCostsPerMember || {})
                .map((memberId) => ({
                    displayName: (store.users[memberId] as IUser)?.displayName || "",
                    formattedCost: formatChf(disciplineStatistic?.totalCostsPerMember[memberId] || 1),
                    costs: disciplineStatistic?.totalCostsPerMember[memberId] || 1,
                }))
                .sort((memberCost1, memberCost2) => memberCost1.costs - memberCost2.costs),
        [disciplineStatistic]
    );

    return (
        <Card className={classNames(className, "total-costs-statistics")}>
            <b>Angefallene Kosten: {formatChf(disciplineStatistic?.totalCosts || 0)}</b>
            <ResponsiveContainer width="100%" height={450}>
                <BarChart margin={{ top: 40 }} data={memberCosts}>
                    <XAxis hide={true} dataKey="displayName" />
                    <YAxis hide={true} />
                    <Bar isAnimationActive={false} radius={20} dataKey="costs">
                        {memberCosts.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                        <LabelList
                            formatter={(val: string | number) => val.toLocaleString().replace(/\s/g, "\u00A0")}
                            className="bar-label bar-label-white"
                            dataKey="displayName"
                            position="inside"
                            angle={-90}
                        />
                        <LabelList className="bar-label" dataKey="formattedCost" position="top" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};
