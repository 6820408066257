import classNames from "classnames";
import React, { useMemo } from "react";
import "./matches-per-team-statistics.scss";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { IGroupStatistics, IUser } from "../../../api/domain";
import { firestore } from "../../../firebase";
import { store } from "react-recollect";
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Card } from "react-onsenui";

interface IOwnProps {
    className?: string;
    groupId: string;
}

interface IMatchesPerTeamStatisticsProps extends IOwnProps {}

const colors = ["#ee6352", "#57a773", "#08b2e3", "#df6bff", "#efe9f4"];

export const MatchesPerTeamStatistics: React.FC<IMatchesPerTeamStatisticsProps> = ({ className, groupId }) => {
    const [groupStatistics] = useDocumentData<IGroupStatistics>(firestore.collection("statistics").doc(groupId));
    const data = useMemo(
        () =>
            Object.values(groupStatistics?.matchesPerTeam || {})
                .map((matchesPerTeam) => ({
                    displayName: Object.keys(matchesPerTeam.members)
                        .map((memberId) => (store.users[memberId] as IUser)?.displayName || "")
                        .join(" & "),
                    matchCount: matchesPerTeam.matchCount,
                }))
                .sort((matchesPerTeam1, matchesPerTeam2) => matchesPerTeam2.matchCount - matchesPerTeam1.matchCount),
        [groupStatistics]
    );
    return data?.length ? (
        <Card className={classNames(className, "matches-per-team-statistics")}>
            <b>Angesagte Mätschli</b>

            <ResponsiveContainer width="100%" height={450}>
                <BarChart data={data}>
                    <XAxis hide={false} dataKey="displayName" angle={-90} height={150} axisLine={false} />
                    <YAxis hide={true} padding={{ bottom: 120 }} />
                    <Bar isAnimationActive={false} radius={5} dataKey="matchCount">
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                        {/*<LabelList*/}
                        {/*    formatter={(val: string | number) => val.toLocaleString().replace(/\s/g, "\u00A0")}*/}
                        {/*    className="bar-label bar-label-white"*/}
                        {/*    dataKey="displayName"*/}
                        {/*    angle={-90}*/}
                        {/*    position="insideTop"*/}
                        {/*/>*/}
                        <LabelList className="bar-label" dataKey="matchCount" position="top" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Card>
    ) : (
        <Card>Noch keine Daten für Match Statistik</Card>
    );
};
