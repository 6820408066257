import classNames from "classnames";
import React, { useMemo } from "react";
import "./costs-timeline-statistics.scss";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { IGroupStatistics, IUser } from "../../../api/domain";
import { firestore } from "../../../firebase";
import { store } from "react-recollect";
import { Card } from "react-onsenui";
import { Bar, ComposedChart, Line, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { IReadGroup } from "../../../api/domain/group";
import { formatCHFAmount } from "../../../util/number-helpers";

interface IOwnProps {
    className?: string;
    groupId: string;
}
const colors = ["#ee6352", "#57a773", "#08b2e3", "#df6bff", "#efe9f4"];

interface ICostsTimelineStatisticsProps extends IOwnProps {}

export const CostsTimelineStatistics: React.FC<ICostsTimelineStatisticsProps> = ({ className, groupId }) => {
    const [disciplineStatistic] = useDocumentData<IGroupStatistics>(firestore.collection("statistics").doc(groupId));
    const [group] = useDocumentData<IReadGroup>(firestore.collection("groups").doc(groupId));
    const members = useMemo(() => Object.keys(group?.members || {}), [group]);
    const CustomTooltip: React.FC<Partial<TooltipProps<any, any>>> = ({ active, payload, label }) => {
        if (!active || !payload?.length) {
            return null;
        }
        const totalCosts = payload?.find((p) => p.dataKey === "totalCosts")?.payload?.totalCosts;
        const dataPerPlayer = members.map(
            (memberUid) =>
                payload
                    .filter((p) => `${p.dataKey}`.includes(memberUid))
                    .map((payload) => ({
                        ...payload.payload[memberUid],
                        color: payload.color,
                        member: (store.users[memberUid] as IUser)?.displayName || memberUid,
                    }))
                    .slice(-1)[0]
        );
        return (
            <div className="custom-tooltip">
                <div className="date">{`${new Date(label).toLocaleDateString("de-ch")}`}</div>
                <br />
                <table>
                    <tbody>
                        <tr>
                            <td>Total</td>
                            <td>{formatCHFAmount(totalCosts)}</td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                        {dataPerPlayer.map((data) => (
                            <React.Fragment key={data.member}>
                                <tr>
                                    <th colSpan={2} style={{ color: data.color }}>
                                        {data.member}
                                    </th>
                                </tr>
                                <tr>
                                    <td>Tag</td>
                                    <td>{formatCHFAmount(data.incrementalCosts)}</td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <td>{formatCHFAmount(data.totalCosts)}</td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return disciplineStatistic?.costsTimelinePerPlayer ? (
        <Card className={classNames(className, "costs-timeline-statistics")}>
            <b>Angefallene Kosten über die Zeit</b>
            <ResponsiveContainer width="100%" height={450} className="costs-timeline-statistics-body">
                <ComposedChart
                    data={disciplineStatistic?.costsTimelinePerPlayer}
                    margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <XAxis dataKey="date" hide={true} />
                    <YAxis yAxisId="grandTotalCostsAxis" hide={true} domain={[0, "dataMax"]} />
                    <YAxis yAxisId="totalCostsAxis" hide={true} domain={[0, "dataMax"]} />
                    <YAxis yAxisId="incrementalCostsAxis" hide={true} domain={[0, "dataMax"]} />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                        type="monotone"
                        dataKey="totalCosts"
                        stroke="#000000"
                        fill="#000000"
                        isAnimationActive={false}
                        yAxisId="grandTotalCostsAxis"
                        strokeDasharray="5 5"
                        dot={false}
                        opacity={0.2}
                    />
                    {members.map((memberUid, idx) => (
                        <Bar
                            yAxisId="incrementalCostsAxis"
                            key={`${memberUid}.incrementalCosts`}
                            type="monotone"
                            dataKey={`${memberUid}.incrementalCosts`}
                            stroke={colors[idx % colors.length]}
                            fill={colors[idx % colors.length]}
                            isAnimationActive={false}
                            stackId="a"
                            opacity={0.2}
                        />
                    ))}
                    {members.map((memberUid, idx) => (
                        <Line
                            yAxisId="totalCostsAxis"
                            key={`${memberUid}.totalCosts`}
                            type="monotone"
                            dataKey={`${memberUid}.totalCosts`}
                            stroke={colors[idx % colors.length]}
                            fill={colors[idx % colors.length]}
                            isAnimationActive={false}
                            dot={false}
                        />
                    ))}
                </ComposedChart>
            </ResponsiveContainer>
        </Card>
    ) : (
        <Card>Noch keine Daten für Jasskassen Verlauf vorhanden</Card>
    );
};
