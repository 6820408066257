import firebase from "firebase";
import { firestore, functions } from "../../firebase";
import { IUser, LogEvent, LogEventType } from "../domain";
import { ICoiffeur, ICoiffeurWithTeams } from "../domain/coiffeur";
import { ICoiffeurConfiguration, IDisciplineConfiguration } from "../domain/coiffeur-configuration";
import { IDiscipline } from "../domain/discipline";
import { IResolvedGroup } from "../domain/group";
import { IResult } from "../domain/result";
import { ITeam } from "../domain/team";

export class CoiffeurService {
    static async getDisciplines(): Promise<IDiscipline[]> {
        const response = await firestore.collection("disciplines").get();
        // @ts-ignore
        const disciplines: IDiscipline[] = response.docs.map((doc) => ({
            uid: doc.id,
            ...doc.data(),
        }));
        return disciplines;
    }

    static async getDiscipline(disciplineId: string): Promise<IDiscipline> {
        const response = await firestore.collection("disciplines").doc(disciplineId).get();
        return response.data() as IDiscipline;
    }

    static getLatestCoiffeurGamesQuery(max: number, userId?: string): firebase.firestore.Query {
        const uid = userId || firebase.auth().currentUser?.uid;
        const coiffeurGames = firestore
            .collectionGroup("coiffeur")
            .where("completed", "==", true)
            .where(`members`, "array-contains", uid)
            .orderBy("date", "desc")
            .limit(max);
        return coiffeurGames;
    }

    static async getCoiffeurWithTeams(
        limit: number = 2,
        startAfter: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now()
    ): Promise<ICoiffeurWithTeams[]> {
        const uid = firebase.auth().currentUser?.uid;
        const coiffeurGamesDocs = await firestore
            .collectionGroup("coiffeur")
            .where(`members`, "array-contains", uid)
            .orderBy("date", "desc")
            .limit(limit)
            .startAfter(startAfter)
            .get();
        const coiffeurGames = await coiffeurGamesDocs.docs.map((games) => games.data() as ICoiffeur);
        const teamsRefs = coiffeurGamesDocs.docs.map((gameRef) => gameRef.ref.collection("teams").get());
        const teamDocs = await Promise.all(teamsRefs);

        return coiffeurGames.map(
            (game, idx) =>
                ({
                    ...game,
                    teams: teamDocs[idx].docs.map((doc) => doc.data() as ITeam),
                } as ICoiffeurWithTeams)
        );
    }

    static async getLatestCoiffeurDisciplines(): Promise<(IDiscipline & { id: number })[]> {
        const uid = firebase.auth().currentUser?.uid;

        const latestGamesRef = firestore
            .collectionGroup("coiffeur")
            .where(`members`, "array-contains", uid)
            .orderBy("date", "desc")
            .limit(1);
        const latestGamesDocs = await latestGamesRef.get();
        if (!latestGamesDocs.size) {
            return [];
        }
        const latestGame = latestGamesDocs.docs[0];
        const disciplinesRef = await latestGame.ref.collection("configuration").get();
        return disciplinesRef.docs
            .map(
                (doc) =>
                    ({
                        ...doc.data(),
                        disciplineId: doc.id,
                    } as IDisciplineConfiguration)
            )
            .sort((d1, d2) => d1.multiplier - d2.multiplier)
            .map(
                (disciplineConfiguration) =>
                    ({
                        name: disciplineConfiguration.name,
                        uid: disciplineConfiguration.disciplineId,
                        id: disciplineConfiguration.multiplier,
                    } as IDiscipline & { id: number })
            );
    }

    static getOngoingCoiffeurGamesQuery(): firebase.firestore.Query {
        const uid = firebase.auth().currentUser?.uid;
        const coiffeurGames = firestore
            .collectionGroup("coiffeur")
            .where("completed", "==", false)
            .where(`members`, "array-contains", uid)
            .orderBy("date", "desc");
        return coiffeurGames;
    }

    static async getOngoingCoiffeurGames(): Promise<ICoiffeur[]> {
        const query = this.getOngoingCoiffeurGamesQuery();
        const coiffeurGames = await query.get();
        return coiffeurGames.docs.map(
            (game) =>
                ({
                    ...game.data(),
                    uid: game.id,
                } as ICoiffeur)
        );
    }

    static getCoiffeurConfigurationRef(groupId: string, coiffeurId: string): firebase.firestore.Query {
        const configurationRef = firestore
            .collection("games")
            .doc(groupId)
            .collection("coiffeur")
            .doc(coiffeurId)
            .collection("configuration")
            .orderBy("multiplier", "asc");
        return configurationRef;
    }

    static getCoiffeurTeamsRef(groupId: string, coiffeurId: string): firebase.firestore.Query {
        const teamsRef = firestore
            .collection("games")
            .doc(groupId)
            .collection("coiffeur")
            .doc(coiffeurId)
            .collection("teams")
            .orderBy("order", "asc");
        return teamsRef;
    }

    static getCoiffeurLogsRef(groupId: string, coiffeurId: string): firebase.firestore.Query {
        const logsRef = firestore
            .collection("games")
            .doc(groupId)
            .collection("coiffeur")
            .doc(coiffeurId)
            .collection("log")
            .orderBy("date", "asc");
        return logsRef;
    }

    static getResultsRef(userId: string, max: number): firebase.firestore.Query {
        return firestore
            .collectionGroup("results")
            .where(`player.uid`, "==", userId)
            .orderBy("date", "desc")
            .limit(max);
    }

    static getCoiffeurResultsRef(coiffeurId: string, groupId: string, teamId: string): firebase.firestore.Query {
        return firestore
            .collection("games")
            .doc(groupId)
            .collection("coiffeur")
            .doc(coiffeurId)
            .collection("teams")
            .doc(teamId)
            .collection("results")
            .orderBy("multiplier", "asc");
    }

    static async getCoiffeur(coiffeurId: string): Promise<ICoiffeur> {
        const coiffeurGames = await firestore.collectionGroup("coiffeur").where("uid", "==", coiffeurId).get();
        if (coiffeurGames.size === 0) {
            throw new Error(`Coiffeur with id ${coiffeurId} not found`);
        }
        const coiffeurRef = coiffeurGames.docs[0];
        const data = coiffeurRef.data();
        return {
            ...data,
        } as ICoiffeur;
    }

    static async deleteCoiffeur(coiffeurId: string): Promise<void> {
        const batch = firestore.batch();
        const coiffeurGames = await firestore.collectionGroup("coiffeur").where("uid", "==", coiffeurId).get();
        if (coiffeurGames.size === 0) {
            throw new Error(`Coiffeur with id ${coiffeurId} not found`);
        }
        const marks = await coiffeurGames.docs[0].ref.collection("marks").get();
        const teams = await coiffeurGames.docs[0].ref.collection("teams").get();
        const configuration = await coiffeurGames.docs[0].ref.collection("configuration").get();
        marks.forEach((doc) => batch.delete(doc.ref));
        teams.forEach((doc) => batch.delete(doc.ref));
        for (let i = 0; i < teams.docs.length; i++) {
            const results = await teams.docs[i].ref.collection("results").get();
            results.forEach((doc) => batch.delete(doc.ref));
        }
        configuration.forEach((doc) => batch.delete(doc.ref));
        batch.delete(coiffeurGames.docs[0].ref);
        await batch.commit();
    }

    static async deleteCoiffeurResult(
        coiffeurId: string,
        groupId: string,
        teamId: string,
        opponentTeamId: string,
        disciplineId: string
    ) {
        let batch = firestore.batch();
        const resultRef = firestore
            .collection("games")
            .doc(groupId)
            .collection("coiffeur")
            .doc(coiffeurId)
            .collection("teams")
            .doc(teamId)
            .collection("results")
            .doc(disciplineId);
        const opponentResultRef = firestore
            .collection("games")
            .doc(groupId)
            .collection("coiffeur")
            .doc(coiffeurId)
            .collection("teams")
            .doc(opponentTeamId)
            .collection("results")
            .doc(disciplineId);
        const resultDoc = await resultRef.get();
        if (!resultDoc.exists) {
            return;
        }
        const { opponentResult } = resultDoc.data() as IResult;
        if (opponentResult) {
            batch.set(resultRef, {
                opponentResult,
            });
        } else {
            batch.delete(resultRef);
        }
        batch.update(opponentResultRef, {
            opponentResult: firebase.firestore.FieldValue.delete(),
        });
        await batch.commit();
        const writeLogEvent: (data: LogEvent) => Promise<any> = functions.httpsCallable("writeLogEvent");
        await Promise.all([
            writeLogEvent({
                type: LogEventType.RESULT_DELETED,
                coiffeurId,
                disciplineId,
            }),
        ]);
    }

    static async addCoiffeurResult(
        coiffeurId: string,
        groupId: string,
        teamId: string,
        opponentTeamId: string,
        { player, match, disciplineId, multiplier, counterMatch, points }: IResult
    ) {
        let batch = firestore.batch();
        const resultRef = firestore
            .collection("games")
            .doc(groupId)
            .collection("coiffeur")
            .doc(coiffeurId)
            .collection("teams")
            .doc(teamId)
            .collection("results")
            .doc(disciplineId);
        const opponentResultRef = firestore
            .collection("games")
            .doc(groupId)
            .collection("coiffeur")
            .doc(coiffeurId)
            .collection("teams")
            .doc(opponentTeamId)
            .collection("results")
            .doc(disciplineId);
        const opponentResultDocument = await opponentResultRef.get();
        const opponentResult = opponentResultDocument.data() as IResult;
        const result: IResult = {
            points,
            counterMatch,
            disciplineId,
            match,
            player,
            multiplier,
            date: firebase.firestore.Timestamp.now(),
        };
        batch.set(resultRef, result);
        if (opponentResult) {
            batch.update(resultRef, { opponentResult });
        }
        if (opponentResultDocument.exists) {
            batch.update(opponentResultRef, {
                opponentResult: result,
            });
        } else {
            batch.set(opponentResultRef, {
                opponentResult: result,
            });
        }
        await batch.commit();
        const writeLogEvent: (data: LogEvent) => Promise<any> = functions.httpsCallable("writeLogEvent");
        await Promise.all([
            writeLogEvent({
                type: LogEventType.RESULT_ADDED,
                coiffeurId,
                disciplineId,
                player,
                match,
                teamId,
                points,
            }),
        ]);
    }

    static async createCoiffeur(
        group: IResolvedGroup,
        teams: IUser[][],
        configuration: ICoiffeurConfiguration
    ): Promise<string> {
        const lastEditor = firebase.auth().currentUser?.uid;
        const groupMembers = group.members.reduce(
            (acc, val) => ({
                ...acc,
                [val.uid]: true,
            }),
            {}
        );
        const teamMembers = teams.flat().map((user) => user.uid);
        let batch = firestore.batch();
        const date = firebase.firestore.Timestamp.now();
        const gamesGroupRef = firestore.collection("games").doc(group.uid);
        const createdCoiffeurRef = await gamesGroupRef.collection("coiffeur").add({
            date,
            completed: false,
            members: teamMembers,
            groupId: group.uid,
            lastEdit: date,
            lastEditor,
        });
        batch.update(createdCoiffeurRef, { uid: createdCoiffeurRef.id });
        batch.set(gamesGroupRef, { members: groupMembers });
        Object.keys(configuration).forEach((disciplineId) => {
            const disciplineRef = createdCoiffeurRef.collection("configuration").doc(disciplineId);
            batch.set(disciplineRef, configuration[disciplineId]);
        });
        teams.forEach((team, idx) => {
            const teamId = [...team.map((m) => m.uid)].sort().reduce((acc, userId) => `${acc}${userId}`, "");
            const members = team.reduce(
                (acc, val) => ({
                    ...acc,
                    [val.uid]: { ...val },
                }),
                {}
            );
            const teamRef = createdCoiffeurRef.collection("teams").doc(teamId);
            batch.set(teamRef, { members, order: idx });
        });
        await batch.commit();
        const writeLogEvent: (data: LogEvent) => Promise<any> = functions.httpsCallable("writeLogEvent");
        void writeLogEvent({
            type: LogEventType.COIFFEUR_STARTED,
            coiffeurId: createdCoiffeurRef.id,
        });
        return createdCoiffeurRef.id;
    }
}
